import {
  useMemo, useContext, useRef, useEffect, useState,
} from 'react';
import MUIDataTable from 'mui-datatables';
import { DateTime } from 'luxon';
import Dialog from '@mui/material/Dialog';
import { orderBy } from 'lodash';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LoadingIndicator } from '../../assets/Svgs/index';
import DateRangeWithOptions from '../../components/date-range/DateRangeWithOptions';
import useAuthorizedToView from '../../hooks/useAuthorizedToView';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';
import ItemActivityModal from '../../components/itemActivityModal/ItemActivityModal';
import Search from '../../components/NewSearch';
import Error from '../../components/alerts/Error';
import { getRfidTableHeader } from '../../components/RFIDTableHeader';
import { buildRowsData } from './itemActivity.utils';

import { SimWebContext } from '../../context/SimWeb.provider';
import { itemActivityContext } from './ItemActivity.provider';

import './ItemActivityPage.css';

const ItemActivityPage = () => {
  const {
    getMessage, isIOS, isOffsiteEnabled, isOffsiteInactive, isPrintingEnabled, storeConfig,
  } = useContext(SimWebContext);

  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const gtinSearch = useRef();
  const [rows, setRows] = useState([]);

  const {
    startDateCurrent,
    endDateCurrent,
    error,
    data,
    hiddenColumns,
    isOpen,
    loading,
    modalStyles,
    searchStyle,
    handleModalClose,
    handleModalOpen,
    isOutsideRange,
    onClickPressed,
    onEnterSearch,
    setHiddenColumns,
    setIndex,
    setModalStyles,
    setRowCount,
  } = useContext(itemActivityContext);

  const title = getMessage('itemActivity');

  useAuthorizedToView(title, 'isItemActivityEnabled');

  useEffect(() => {
    setRows(buildRowsData(data, rfidEnabled, isOffsiteEnabled));
  }, [data]);

  const pageContent = useMemo(() => {
    if (loading) return <LoadingIndicator />;

    if (searchStyle && (error || (!loading && !data))) {
      return (
        <Error
          apiName="Item Activity API"
          errorObject={error}
        />
      );
    }

    let columns = [
      {
        name: 'styleColor',
        label: getMessage('style-color'),
      },
      {
        name: 'sale',
        label: getMessage('unitsSold'),
      },
      {
        name: 'shipFromStore',
        label: getMessage('shipFromStore'),
      },
      {
        name: 'received',
        label: getMessage('received'),
      },
      {
        name: 'returned',
        label: getMessage('returns'),
      },
      {
        name: 'transfer',
        label: getMessage('transfers'),
      },
      {
        name: 'adjustment',
        label: getMessage('adjustments'),
      },
      {
        name: 'postVoidSale',
        label: getMessage('postVoidSales'),
        options: {
          display: false,
        },
      },
      {
        name: 'postVoidReturn',
        label: getMessage('postVoidReturns'),
        options: {
          display: false,
        },
      },
      // GOLINV-22401 - hide online sale column
      // {
      //   name: 'onlineSale',
      //   label: getMessage('onlineSales'),
      // },
      {
        name: 'soh',
        label: getMessage('soh'),
      },
    ];

    if (rfidEnabled) {
      columns = [...columns,
        getRfidTableHeader('rfidSoh', getMessage('rfidSoh'), 'item-activity', true, isOffsiteEnabled, isOffsiteInactive)];
    }

    const theme = createTheme(({
      components: {
        MuiTableRow: {
          styleOverrides: {
            root: {
              cursor: 'pointer',
            },
          },
        },
      },
    }));

    return (
      <div data-testid="itemActivityTableDiv">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <MUIDataTable
              data-testid="itemActivityTable"
              title={title}
              data={rows}
              columns={columns}
              options={{
                selectableRows: 'none',
                rowsPerPage: 100,
                rowsPerPageOptions: [15, 25, 50, 100],
                print: isPrintingEnabled,
                responsive: !isIOS ? 'standard' : 'stacked',
                filter: false,
                textLabels: {
                  body: {
                    noMatch: getMessage('noData'),
                  },
                },
                onRowClick: (rowData, { dataIndex }) => {
                  const styleColor = rowData[0];
                  setModalStyles(styleColor);
                  setIndex(dataIndex);
                  setRowCount(rows?.length);
                  handleModalOpen();
                },
                downloadOptions: {
                  filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true,
                  },
                },
                // ignoring lint rule bc the data isn't rendering properly if i update to the perfered-template

                onDownload: (buildHead, buildBody, newColumns, newData) =>
                  '\uFEFF' + buildHead(newColumns) + buildBody(newData),
                onColumnViewChange: (changedColumn, action) => {
                  const newHiddenColumns = hiddenColumns;
                  if (action === 'remove') {
                    newHiddenColumns[changedColumn] = true;
                  } else {
                    delete newHiddenColumns[changedColumn];
                  }
                  setHiddenColumns(newHiddenColumns);
                },
                onColumnSortChange: (changedColumn, direction) => {
                  setRows(orderBy(rows, changedColumn, direction));
                },
              }}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    );
  }, [loading, data, hiddenColumns, rfidEnabled, isOffsiteEnabled, getMessage, isIOS, rows]);

  return (
    <div>
      <div className="title-block">
        <div className="feature-title">{getMessage(title)}</div>
      </div>
      <div className="header-container">
        <div className="header-left">
          <div className="gtin-search">
            <div data-testid="searchBox">
              <Search
                reference={gtinSearch}
                searchLabel={getMessage('styleUpc')}
                onEnterSearch={onEnterSearch}
              />
            </div>
          </div>
          <div className="header-dates" data-testid="itemActiviyDatesSelector">
            <DateRangeWithOptions
              data-testid="datesSelector"
              start={startDateCurrent}
              end={endDateCurrent}
              onClick={(e) => { onClickPressed(gtinSearch, e); }}
              disabled={loading}
              isOutsideRange={isOutsideRange}
            />
          </div>
        </div>
      </div>
      <br />
      {pageContent}
      <Dialog
        open={isOpen}
        onClose={handleModalClose}
        maxWidth="lg"
        scroll="paper"
        fullWidth
      >
        <ItemActivityModal
          styleInput={modalStyles}
          startDate={DateTime.fromISO(startDateCurrent).toLocaleString(DATE_SHORT_WITH_APPENDED_ZEROS)}
          endDate={DateTime.fromISO(endDateCurrent).toLocaleString(DATE_SHORT_WITH_APPENDED_ZEROS)}
          productData={data}
          loading={loading}
          closeCall={handleModalClose}
          hiddenColumns={hiddenColumns}
          rows={rows}
          data-testid="itemActivityModal"
        />
      </Dialog>
    </div>
  );
};

export default ItemActivityPage;
